<template>
    <div class="d-block">
        <div
            class="overflow-hidden border-0 mb-0 d-inline-block"
            v-for="(image, index) in imageSlider"
            :key="index"
            :style="{ width: imagesWidth }"
            @click="openSlider(index)"
        >
            <div
                class="image-gallery-preview"
                ref="image"
                :style="{ 'background-image': `url('${image.path}')` }"
                style="cursor: zoom-in"
            ></div>
        </div>

        <vue-image-lightbox-carousel
            ref="slider"
            :images="imageSlider"
            :show="showImageSlider"
            @close="showImageSlider = false"
        />
    </div>
</template>

<script>
import VueImageLightboxCarousel from "vue-img-lightbox-fixed"

export default {
    name: "ImageGallery",
    components: { VueImageLightboxCarousel },
    props: {
        imagesId: {
            type: Array
        },
        imagesWidth: {
            type: String,
            default: "150px"
        }
    },
    data() {
        return {
            showImageSlider: false
        }
    },
    updated() {
        document.body.appendChild(this.$refs.slider.$el)
    },
    computed: {
        imageSlider() {
            return this.imagesId.map((f) => ({ path: platonFileUrl(f) }))
        }
    },
    methods: {
        openSlider(index) {
            this.showImageSlider = true
            this.$refs.slider.showImage(index)
        }
    }
}
</script>
